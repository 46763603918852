import { useRef, useEffect, MutableRefObject } from "react"
import { SpringSystem } from "rebound"

const springSystem = new SpringSystem()
type Element = SVGElement | HTMLElement

function useParallax<T extends Element>(
  defaultRef: T | null,
  callback: (el: Element, val: number) => void
): MutableRefObject<T | null> {
  const spring = springSystem.createSpring(30, 10)
  const ref = useRef<T | null>(defaultRef)
  const initialPos = useRef<{ top: number; bottom: number } | null>(null)

  const handleScroll = () => {
    if (!ref.current || !initialPos.current) return

    if (ref.current.getBoundingClientRect().bottom < 0) {
      return
    }

    const val = Math.max(
      Math.min(
        window.pageYOffset - initialPos.current.top + window.innerHeight,
        window.pageYOffset
      ),
      0
    )

    spring.setEndValue(val)
  }

  useEffect(() => {
    if (!ref.current) {
      return
    }

    initialPos.current = ref.current.getBoundingClientRect()

    document.addEventListener("scroll", handleScroll, { passive: true })
    spring.setEndValue(0.01) // trigger initial layout when translateX is passed

    spring.addListener({
      onSpringUpdate: spring => {
        if (ref.current) {
          callback(ref.current, spring.getCurrentValue())
        }
      },
    })

    return () => {
      spring.destroy()
      document.removeEventListener("scroll", handleScroll)
    }
  }, [ref.current])

  return ref
}

export default useParallax

export const makeTransformY = (speed = 1, translateX = "0") => (
  el: HTMLElement | SVGElement,
  val: number
) => {
  const translate = val * (speed / 10)
  el.style.webkitTransform = el.style.transform = `translate3d(${translateX}, ${translate}px, 0)`
}
