import React from "react"
import { oc } from "ts-optchain"
import { useStaticQuery, graphql } from "gatsby"
import { GrainImgQuery } from "../../generated/graphql"

export default function GrainPattern({
  id,
  x = "0",
  y = "0",
}: {
  id: string
  x?: string
  y?: string
}) {
  const scale = 0.625
  const bg: GrainImgQuery = useStaticQuery(graphql`
    query GrainImg {
      file(name: { eq: "grain-fs8" }) {
        id
        childImageSharp {
          fixed(width: 1867) {
            width
            height
            src
          }
        }
      }
    }
  `)
  const height = oc(bg).file.childImageSharp.fixed.height(0) * scale
  const width = oc(bg).file.childImageSharp.fixed.width(0) * scale

  return (
    <defs>
      <pattern
        id={id}
        patternUnits="userSpaceOnUse"
        height={height}
        width={width}
        x={x}
        y={y}
      >
        <rect x="0" y="0" height={height} width={width} />
        <image
          x="0"
          y="0"
          height={height}
          width={width}
          xlinkHref={oc(bg).file.childImageSharp.fixed.src("")}
        ></image>
      </pattern>
    </defs>
  )
}
